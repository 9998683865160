<template>
  <div class="">
    <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime excepturi sapiente, illum quibusdam reiciendis, explicabo perferendis voluptate distinctio cumque autem tenetur porro, asperiores laborum tempora exercitationem. Fuga provident asperiores laboriosam? -->
  </div>
</template>
<script>
const null_array = [];
import "core-js/stable";
import Vue from "vue";
export default {
  name: "AutoLogin",
  data() {
    return {
      access_token: this.$route.query.access_token,
    };
  },

  created() {
    let self = this;
    console.log(this.access_token);
    Swal.fire({
      title: '<i class="fa fa-refresh fa-spin"></i>',
      text: "Mohon tunggu sebentar...",
      showConfirmButton: false,
    });
    axios
      .get(this.apiLink + "api/user", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then(response => {
        console.log(response.data.data.roles);
        Vue.prototype.access_token = this.access_token;
        Vue.prototype.session = JSON.stringify(response.data.datanpm);
        Vue.prototype.session_nama = response.data.data.name;
        Vue.prototype.reload_already = "no";
        localStorage.session = JSON.stringify(response.data.data);
        localStorage.session_nama = response.data.data.name;
        localStorage.access_token = this.access_token;
        localStorage.reload_already = "no";
        localStorage.dateLogin = Date.now();

        let timerInterval;
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Anda akan segera diarahkan ke halaman dashboard.",
          timer: 2000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: () => {
            timerInterval = setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(result => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            if (response.data.data.roles == "PEMOHON") {
              self.$router.push({ name: "DashboardPemohon" });
            } else {
              self.$router.push({ name: "Dashboard" });
            }
          }
        });
      });
  },
};
</script>
